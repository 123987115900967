export const prettyDate = (date: Date | string | number, time = false) => {
  if (typeof date === "number") {
    date = new Date(date * 1000)
  } else if (typeof date === "string") {
    if (date.indexOf("Z") == -1) date += "Z"
    date = new Date(date)
  }
  const diff = (new Date().getTime() - date.getTime()) / 1000
  const dayDiff = Math.floor(diff / 86400)

  return isNaN(dayDiff) || dayDiff < 0
    ? null
    : (dayDiff == 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
        (dayDiff == 1 && "Yesterday") ||
        (dayDiff < 7 && dayDiff + " days ago") ||
        (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago") ||
        (dayDiff < 61 && "1 month ago") ||
        (dayDiff < 365 && Math.ceil(dayDiff / 30) + " months ago") ||
        (dayDiff < 730 && " 1 year ago") ||
        Math.ceil(dayDiff / 365) + " years ago"
  /*(time ? date.toLocaleString() : date.toLocaleDateString())*/
}

export const exactDate = (ts: number) => {
  let d = new Date(ts * 1000)
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d)
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
  //console.log(`${da}-${mo}-${ye}`);
  const time =
    d.getHours() + ":" + (d.getMinutes() < 10 ? "0" : "") + d.getMinutes() // + ":" + d.getSeconds()

  return `${time} ${da}-${mo}`
}

export const exactDateWithYear = (ts: number) => {
  let d = new Date(ts)
  const ye = d.getFullYear().toString().substr(-2)
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d)
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
  //console.log(`${da}-${mo}-${ye}`);
  const time =
    d.getHours() +
    ":" +
    (d.getMinutes() < 10 ? "0" : "") +
    d.getMinutes() +
    ":" +
    (d.getSeconds() < 10 ? "0" : "") +
    d.getSeconds()

  return `${time} ${da}-${mo}-${ye}`
}

export const exactDateWithSeconds = (ts: number) => {
  let d = new Date(ts)
  const ye = d.getFullYear().toString().substr(-2)
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d)
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
  //console.log(`${da}-${mo}-${ye}`);
  const time =
    d.getHours() +
    ":" +
    (d.getMinutes() < 10 ? "0" : "") +
    d.getMinutes() +
    ":" +
    (d.getSeconds() < 10 ? "0" : "") +
    d.getSeconds()

  return `${time} ${da}-${mo}`
}
