import { forwardRef, DetailedHTMLProps, ButtonHTMLAttributes } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { useStyle } from "../../../hooks/use-style"
import { lightTheme, darkTheme } from "../../../services/theme"
import React from "react"

export type Props = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    loading?: boolean
}

const SubmitButton = forwardRef<HTMLButtonElement, Props>(
    ({ loading: isLoading, ...props }, ref) => {
        const css = useStyle(theme => ({
            buttonBackgroundColor:
                theme === "light"
                    ? lightTheme.color.active
                    : darkTheme.color.active,
            inactiveButtonBackgroundColor:
                theme === "light"
                    ? lightTheme.color.border
                    : darkTheme.color.border,
            buttonTextColor: "black",
            inactiveButtonTextColor: "gray",
        }))
        const buttonBackgroundColor = props.disabled
            ? css.inactiveButtonBackgroundColor
            : css.buttonBackgroundColor
        const inactiveButtonTextColor = props.disabled
            ? css.inactiveButtonTextColor
            : css.buttonTextColor

        return (
            <>
                <button ref={ref} {...props}>
                    {isLoading ? (
                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                    ) : (
                        props.children
                    )}
                </button>

                <style jsx>{`
                    button {
                        border: none;
                        border-radius: 0.3em;
                        background-color: ${buttonBackgroundColor};
                        transition: 200ms background-color, 200ms color;
                        padding: 0.5em 1em;
                        color: black;
                        min-width: 7.5em;
                    }
                    button:disabled {
                        color: ${inactiveButtonTextColor};
                    }
                `}</style>
            </>
        )
    }
)

export default SubmitButton
