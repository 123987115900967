import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useFilters } from "../../hooks/use-filters"
import { useLazyLoading } from "../../hooks/use-lazy-loading"
import { useUser } from "../../hooks/use-user"
import { Supporter } from "../../types"
import CompactPreview from "../channel/compact-preview"
import UserSupporterPreview from "../user/supporter-preview"

type Props = {}

const SupportersFeed: FunctionComponent<Props> = ({}) => {
  const [items, setItems] = useState<Supporter[]>([])
  const { filters } = useFilters()
  const { user } = useUser()

  const fetchPage = useCallback(
    async (offset: number) => {
      if (user) {
        let url = "/api/v2/supporters/" + user.id + "?count=30&offset=" + offset

        //console.log(url)

        const response = await Promise.resolve(
          fetch(url)
            .then(res => res.json())
            .catch(error => {
              console.log("json parsing error occured ", error)
              return null
            })
        )
        if (!response || !response.hits) return []

        let r = response.hits.hits
        let items: Supporter[] = []
        for (var i = 0; i < r.length; i++) {
          let item = r[i]["_source"]
          item.amount = item.usd_amount * user.displayed_currency_rate
          items.push(item)
        }

        console.log(items)
        return items
      } else return []
    },
    [user, filters]
  )

  const add = useCallback(
    (newItems: Supporter[], offset: number) => {
      console.log(newItems)
      if (offset == 0 && items.length > 0) {
        console.log("clearing items")
        setItems([])
      }
      if (newItems && newItems.length) {
        setItems(items => items.concat(newItems))
      }
      // TODO: trigger single rererender
    },
    [items]
  )

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<Supporter>(items.length ? items : [], { fetchPage, add })

  /*const getContent = useCallback((items: Channel[]) => {
      const itemsCount = 3
      const content: Channel[] = items
      return content
    }, [])
  
    const content = useMemo(() => getContent(items), [items.length, filters])*/

  useEffect(() => {
    //console.log("Feed: filters changed " + filters.channel_ids)
    setItems([])
    resetLoader()
  }, [filters])

  return (
    <div>
      <ul>
        {items.map(x => (
          <li key={x.donor}>
            <UserSupporterPreview supporter={x} />
          </li>
        ))}
      </ul>
      <style jsx>
        {`
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          li:not(:first-child) {
            /*margin-top: 1em;*/
          }
          li {
            margin-bottom: 5px;
          }
          .loader {
            padding: 5em 1em;
            text-align: center;
          }
          span {
            margin-left: 0.5em;
          }
          p {
            margin-top: 100px;
            padding: 20px;
            text-align: center;
          }
        `}
      </style>
    </div>
  )
}

export default SupportersFeed
