import { useEffect } from "react"
import nProgress from "nprogress"

export const useLoader = (isLoading: boolean) => {
  useEffect(() => {
    nProgress.configure({ showSpinner: false })
  }, [])

  useEffect(() => {
    if (isLoading) {
      //nProgress.configure({ showSpinner: false })
      nProgress.start()
    } else {
      nProgress.done()
    }
  }, [isLoading])
}
