import React, { FunctionComponent } from "react"
import { Helmet as Head } from "react-helmet"
import MinimalSortFilter from "../components/layout/minimal-sort-filter"
import SortFilter from "../components/layout/sort-filter"
import SupportNetwork from "../components/layout/support-network"
import SupportersFeed from "../components/layout/supporters-feed"
import ThreeColumnLayout from "../components/layout/three-column-layout"
import Notice from "../components/widgets/notice"
import { useUser } from "../hooks/use-user"

const SupportersPage: FunctionComponent = () => {
  const { user } = useUser()
  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ThreeColumnLayout>
        {{
          content: (
            <>
              <Notice id="supporters_notice" submit="Got it">
                <p>
                  With Smartlike one can create recurring donations starting
                  from 0.01 USD per month. Private, no cuts, can be cancelled
                  anytime.
                  <br />
                  This feed will show the list of your supporters.
                </p>
              </Notice>

              <SupportersFeed />
            </>
          ),
          sidebar: (
            <>
              <MinimalSortFilter alwaysShow={true} />
              {user && user.support && <SupportNetwork />}
            </>
          ),
        }}
      </ThreeColumnLayout>
    </>
  )
}
export default SupportersPage
