import { useEffect, useCallback, useState } from "react"
import { unstable_batchedUpdates } from "react-dom"
import { useLoader } from "./use-loader"

export const useLazyLoading = <T>(
  xs: T[],
  actions: {
    fetchPage: (offset: number) => Promise<T[] | undefined>
    add: (xs: T[], offset: number) => void
  }
) => {
  const [offset, setOffset] = useState(xs.length ? 1 : 0)
  const [isLoading, setLoading] = useState(false)
  const [isContentAvailable, setContentAvailable] = useState(true)
  const [paused, setPaused] = useState(false)

  const loadMore = useCallback(
    async (reset: boolean) => {
      /*console.log(
        "loadMore " + isContentAvailable + " " + isLoading + " " + offset
      )*/
      if (reset) {
        const data = await actions.fetchPage(0)
        if (data?.length) {
          unstable_batchedUpdates(() => {
            actions.add(data, 0)
            setOffset(1)
            setLoading(false)
          })
        } else {
          unstable_batchedUpdates(() => {
            actions.add([], 0)
            setContentAvailable(false)
            setLoading(false)
          })
        }
      } else if (isContentAvailable && !isLoading) {
        setLoading(true)
        const data = await actions.fetchPage(offset)
        if (data?.length) {
          unstable_batchedUpdates(() => {
            actions.add(data, offset)
            setOffset(offset => offset + 1)
            setLoading(false)
          })
        } else {
          unstable_batchedUpdates(() => {
            setContentAvailable(false)
            setLoading(false)
          })
        }
      }
    },
    [offset, isLoading, isContentAvailable, actions.fetchPage, actions.add]
  )

  const onScroll = useCallback(async () => {
    if (!paused) {
      const isTriggered =
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - window.innerHeight / 2

      if (isTriggered) {
        loadMore(false)
      }
    }
  }, [loadMore, paused])

  const resetLoader = useCallback(async () => {
    if (true) {
      //unstable_batchedUpdates(() => {
      //setOffset(0)
      //setContentAvailable(true)
      loadMore(true)
      //})
    }
  }, [setOffset, setContentAvailable, loadMore])

  /**
   * Client side routing doesn't resolve resources before rendering
   * to speed up page loading times.
   */

  /*useEffect(() => {
    loadMore(true)
  }, [])*/

  /**
   * Display loader while fetching
   */
  useLoader(isLoading)

  /**
   * Lazy load on scroll
   */
  useEffect(() => {
    if (!isLoading && isContentAvailable) {
      const timeout = setTimeout(() => {
        window.addEventListener("scroll", onScroll)
      })
      return () => {
        clearTimeout(timeout)
        window.removeEventListener("scroll", onScroll)
      }
    }
  }, [onScroll, isLoading, isContentAvailable])

  return { isLoading, isContentAvailable, resetLoader, paused, setPaused }
}
