import {
  faInfinity,
  faTrafficLightStop,
  faBalanceScaleLeft,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useEffect, useRef } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useUser } from "../../hooks/use-user"
import { useStyle } from "../../hooks/use-style"
import { darkTheme, lightTheme } from "../../services/theme"
import { useSideMode, SideMode } from "../../hooks/use-side-mode"

type Props = {
  alwaysShow?: boolean
}

const MinimalSortFilter: FunctionComponent<Props> = ({
  alwaysShow: forceShow = false,
}) => {
  const { commentFilters, updateCommentFilters } = useFilters()
  const { sideMode } = useSideMode()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,

    activeColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
  }, [])

  const hydrationBugWorkaroundKey = firstUpdate.current ? `client` : `server`

  return (
    <>
      {(sideMode == SideMode.Comment ||
        sideMode == SideMode.Donation ||
        forceShow) && (
        <div className="accordion">
          <section>
            <header>
              <div style={{ display: "inlineFlex" }}>
                <ul className="filterConrols">
                  <li
                    className={
                      commentFilters.sort === "date"
                        ? "controlItem selected"
                        : "controlItem"
                    }
                    onClick={() => updateCommentFilters({ sort: "date" })}
                    key={"date" + hydrationBugWorkaroundKey}
                  >
                    Latest
                  </li>
                  <li
                    className={
                      commentFilters.sort === "amount"
                        ? "controlItem selected"
                        : "controlItem"
                    }
                    onClick={() => updateCommentFilters({ sort: "amount" })}
                    key={"amount" + hydrationBugWorkaroundKey}
                  >
                    Top
                  </li>
                </ul>
              </div>
            </header>
          </section>

          <style jsx>{`
          header {
            display: block;
            justify-content: space-between;
            align-items: center;
            padding: 1em;
            font-family: Roboto;
          }
          h1 {
            margin: 0;
            padding: 0;
            font-size: inherit;
            font-style: inherit;
            font-weight: normal;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-weight: lighter;
          }
  
          .accordion {
            background-color: ${css.backgroundColor};
            border: 1px solid ${css.borderColor};
            transition: 200ms background-color, 200ms border-color;
            border-radius: 0.3em;
            font-size: 0.9em;
            user-select: none;
          }
          section:not(:first-child) {
            border-top: 1px solid ${css.borderColor};
            transition: 200ms border-color;
          }
          .content {
            padding: 5px;
            border-top: 1px solid ${css.borderColor};
            flex-wrap: wrap;
            display: inline-flex;
          }
          .tag {
            background-color: rgb(105, 105, 105, 0.1);
            padding: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            font-size: 14px;
            word-break: keep-all;
            user-select: none;
          }
          .tag:hover {
            background-color: rgb(105, 105, 105, 0.3);
            cursor: pointer;
          }
          .filterConrols {
            display: inline-flex;
            border: 1px solid ${css.borderColor};
            border-radius: 20px;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: ${css.inputBackground};
          }
  
          .controlIcon {
            display: inline-flex;
            color: rgb(108, 121, 127);
            height: 40px;
            width: 40px;
            margin-left: 3px;
          }
          .thumbsUp {
            margin: auto;
          }
          .thumbsUp:hover {
            color: blue;
            cursor: pointer;
          }
  
          .controlItem {
            padding: 0 15px;
            position: relative;
            display: block;
            margin: 0;
            height: 100%;
  
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: -1px;
            height: 40px;
            border: 2px solid transparent;
            border-radius: 3px;
            color: #6c797f;
            font-weight: 700;
            font-size: 13px;
            font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI", Arial,
              sans-serif;
            text-decoration: none;
            cursor: pointer;
          }
          .periodControlItem {
            width: 25%;
            position: relative;
            display: block;
            margin: 0;
            height: 100%;
  
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: -1px;
            height: 32px;
            border: 2px solid transparent;
            border-radius: 3px;
            color: #6c797f;
            font-weight: 700;
            font-size: 12px;
            font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI", Arial,
              sans-serif;
            text-decoration: none;
            cursor: pointer;
          }
  
          .selected {
            /*border: 1px solid ${css.activeColor};*/
            border-radius: 20px;
            background-color: rgb(251, 230, 162);
          }
          .onair {
            border: 1px solid ${css.activeColor};
            border-radius: 20px;
            /*background-color: #ff000052;*/
            background-color: #ffff0085;
          }
  
          .disputed {
            border: 1px solid ${css.activeColor};
            border-radius: 20px;
            background-color: #ff00002e;
          }
  
          .filterAction {
            font-size: 20px;
          }
          .filterAction:hover {
            cursor: pointer;
          }
        `}</style>
        </div>
      )}
    </>
  )
}

export default MinimalSortFilter
