import {
  faExchange,
  faStar,
  faUserFriends,
  faChartNetwork,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { prettyAmount, prettyCount } from "../../services/number"
import { darkTheme, lightTheme } from "../../services/theme"
import HtmlTooltip from "../widgets/html-tooltip"

const SupportNetwork: FunctionComponent = () => {
  const { filters, updateFilters } = useFilters()
  const { user, addBookmark } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  //console.log("**** " + filters)

  return (
    <>
      {user && (
        <div className="accordion">
          <section>
            <header>
              <h1>
                <FontAwesomeIcon icon={faChartNetwork} size="lg" />
                <span>Support network stats</span>
              </h1>
            </header>
          </section>

          <section>
            <div className="content">
              <div className="tt">
                <div className="tr" style={{ opacity: 0.8, fontSize: "12px" }}>
                  <div className="tc"></div>
                  <div className="tc"></div>

                  <div className="tcs" style={{ width: "60px" }}>
                    people
                  </div>
                  <div className="tcs">
                    total per month,{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: user.balance.currency_symbol,
                      }}
                    />
                  </div>
                </div>
                <div className="tr">
                  <div
                    className="tc"
                    style={{ fontSize: "14px", width: "30px" }}
                  >
                    <FontAwesomeIcon icon={faUserFriends} style={{}} />
                  </div>
                  <div className="tc">My supporters</div>
                  <div className="tcs">
                    {prettyCount(user.support.users_in)}
                  </div>
                  <div className="tcs">
                    {prettyAmount(
                      user.support.amount_in * user.displayed_currency_rate
                    )}
                  </div>
                </div>
                <div className="tr">
                  <div className="tc" style={{ fontSize: "14px" }}>
                    <FontAwesomeIcon icon={faStar} style={{}} />
                  </div>

                  <div className="tc">I support</div>
                  <div className="tcs">
                    {prettyCount(user.support.users_out)}
                  </div>
                  <div className="tcs">
                    {prettyAmount(
                      user.support.amount_out * user.displayed_currency_rate
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <style jsx>{`
            .tt {
              display: table;
              font-family: "Roboto";
              width: 100%;
            }
            .tr {
              display: table-row;
              height: 24px;
            }
            .tc {
              display: table-cell;
            }
            .tcs {
              display: table-cell;
              text-align: center;
            }

            .accordion {
              background-color: ${css.backgroundColor};
              border: 1px solid ${css.borderColor};
              transition: 200ms background-color, 200ms border-color;
              border-radius: 0.3em;
              font-size: 0.9em;
              user-select: none;
            }
            section:not(:first-child) {
              border-top: 1px solid ${css.borderColor};
              transition: 200ms border-color;
            }
            .content {
              border-top: 1px solid ${css.borderColor};
              flex-wrap: wrap;
              display: inline-flex;
              width: 100%;
              padding: 20px;
            }
            header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1em;
              font-family: Roboto;
            }
            header > :global(:not(:first-child)) {
              margin-left: 1em;
            }

            h1 {
              margin: 0;
              padding: 0;
              font-size: inherit;
              font-style: inherit;
              font-weight: normal;
              display: flex;
              align-items: center;
              text-transform: uppercase;
              font-weight: lighter;
            }
            h1 > *:not(:first-child) {
              margin-left: 1em;
            }
          `}</style>
        </div>
      )}
    </>
  )
}

export default SupportNetwork
