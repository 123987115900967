import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@reach/router"
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from "react"
import { useStyle } from "../../hooks/use-style"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { encrypt } from "../../services/crypto"
import { rpc } from "../../services/node"
import { darkTheme, lightTheme } from "../../services/theme"
import { DirectMessage, DirectOutMessage } from "../../types"
import SubmitButton from "../layout/form/submit-button"
import { showToast } from "../status-notification"

type Props = {
  initMessage: DirectOutMessage
  addMessageToFeed?: (msg: DirectMessage) => void
  close: () => void
}

const DirectMessageDialog: FunctionComponent<Props> = ({
  initMessage,
  addMessageToFeed,
  close,
}) => {
  const { user, updateBalance } = useUser()
  const [message, setMessage] = useState(initMessage)

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onMessageChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      //console.log(event.currentTarget.value)
      if (event.currentTarget.value.length > 280) return
      setMessage({
        ...message,
        ...{ text: event.currentTarget.value },
      })
    },
    [message]
  )

  const onAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMessage({
        ...message,
        ...{ to: event.currentTarget.value },
      })
    },
    [message]
  )

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      if (user) {
        /*
        if (initMessage.to.length == 0) {
          if (
            message.to.match(
              "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
            ) !== null
          )
            meta.to.handle = address
          else meta.to.handle = address
        }*/

        let recipientPublicKey = null // todo: cache public keys in local DB

        if (!recipientPublicKey) {
          recipientPublicKey = await new Promise(function (resolve) {
            rpc(
              "get_dm_public_key",
              message.to,
              user,
              getCachedSecret(),
              function (res) {
                if (res.status == "ok") {
                  resolve(res.data)
                } else {
                  showToast(
                    faInfoCircle,
                    "Smartlike network",
                    "Failed to obtain recipient's encryption key"
                  )
                  resolve(null)
                }
              }
            )
          })
        }

        if (recipientPublicKey) {
          /*console.log("recipientPublicKey " + recipientPublicKey)
          console.log(
            "user.dm_keys.dm_public_key " + user.dm_keys.dm_public_key
          )*/

          let text = message.text
          message.text = await encrypt(text, recipientPublicKey)
          //console.log("Encrypted: " + message.text)
          message.text_outbox = await encrypt(text, user.dm_keys.dm_public_key)
          //console.log("Encrypted: " + message.text_outbox)

          rpc(
            "send_message",
            JSON.stringify(message),
            user,
            getCachedSecret(),
            function (res) {
              let statusMessage
              if (res.status == "ok") {
                statusMessage = "Message sent"
                if (user) updateBalance(-0.01 * user.displayed_currency_rate)
                if (typeof addMessageToFeed !== "undefined") {
                  let feedMessage = {
                    from: message.from,
                    to: message.to,
                    text: text,
                    ts: Math.floor(Date.now()),
                    id:
                      Math.floor(Date.now()).toString() +
                      Math.floor(Math.random() * 100000000).toString(),
                  }
                  console.log("dialog: add message ")
                  res.data.message.text = text
                  addMessageToFeed(res.data.message)
                }
              } else statusMessage = "Failed to send message"

              showToast(faInfoCircle, "Smartlike network", statusMessage)
            }
          )
        }
      }
      close()
    },
    [user, message, initMessage]
  )

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            close()
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <h1>
        Send a message to{" "}
        {initMessage && initMessage.to.length > 0 ? (
          <span style={{ fontFamily: "arial", color: "grey" }}>
            {initMessage.to}
          </span>
        ) : (
          <input
            value={message.to}
            onChange={onAddressChange}
            placeholder="Account name, number or domain"
            className="address"
            style={{ marginTop: "10px" }}
          />
        )}
      </h1>

      <form onSubmit={onSubmit}>
        <div style={{ marginBottom: "10px", lineHeight: "18px" }}></div>

        <div className="component">
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <textarea
              id="message"
              type="text"
              value={message.text}
              onChange={onMessageChange}
              className="address"
              placeholder="Message text..."
              rows="7"
            ></textarea>
            <div style={{ textAlign: "right", fontSize: "13px" }}>
              {message.text.length}/280
            </div>
            <div style={{ paddingTop: "5px", fontSize: "12px" }}>
              To guard the beta system from spam sending the message will invoke
              a 1 cent (USD) donation to a random charity from this{" "}
              <Link to="/charities">list</Link>
            </div>
          </div>
        </div>
        <footer>
          <SubmitButton
            disabled={
              message && (message.text.length == 0 || message.to.length == 0)
            }
          >
            Send
          </SubmitButton>
        </footer>
      </form>

      <style jsx>{`
        ::placeholder {
          color: rgb(129, 129, 129);
          opacity: 1; /* Firefox */
        }

        footer {
          text-align: right;
        }

        .inline {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
        }
        .close:hover {
          cursor: pointer;
        }
        .highlighted {
          font-weight: 400;
          font-size: 14px;
        }

        .amountCell {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          width: 80px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .address {
          width: 100%;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          line-height: 18px;
        }
        .address:focus,
        .address:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
      `}</style>
    </section>
  )
}

export default DirectMessageDialog
